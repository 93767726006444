<template>
  <v-container>
    <LogoHeader></LogoHeader>
    <v-row justify="center">
      <v-card class="mt-6 ml-2 mr-2">
        <v-card-title
          ><v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-4
              mb-2
            "
            color="#5e72e4"
            to="/"
            ><v-icon>fas fa-chevron-left</v-icon> Strona główna</v-btn
          >
          <span class="text-h5 text-center"> Regulamin aplikacji VIVEFIT</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <tos-text></tos-text>
        </v-card-text>
        <v-card-actions :style="'display: flex; justify-content: center;'">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-4
              mb-2
            "
            color="#5e72e4"
            to="/login"
            >Zaloguj</v-btn
          >

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-4
              mb-2
            "
            color="#5e72e4"
            href="https://cdn.filestackcontent.com/tmMUJeGKSw2E2kqNzTBX"
            target="_blank"
            >Regulamin konkursu</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import TosText from "@/components/util/TOS.vue";
import LogoHeader from "@/components/util/LogoHeader.vue";
export default {
  name: "TOS",
  components: {
    TosText,
    LogoHeader,
  },
};
</script>

<style>
</style>